<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ $t('warehouse.add_item') }}</h2>
        <div @click="$emit('close')" v-if="isModal">
          <b-icon class="cursor-pointer" icon="close-thick"/>
        </div>
      </header>
      <ValidationObserver ref="form">
        <form class="mt-3" @submit.prevent="handleSubmit">
          <section class="my-2">
            <ValidationProvider :name="$t('warehouse.product_name')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('warehouse.product_name')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-autocomplete
                    append-to-body
                    open-on-focus
                    keep-first
                    :data="products"
                    :placeholder="$t('search') + ' ' + $t('warehouse.product_name')"
                    field="product_name"
                    :loading="isFetchingProduct"
                    @typing="searchProduct"
                    v-model="form.product_name"
                    @select="handleSelectProduct"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('category')" rules="required" v-slot="{ errors }" v-if="!isRemove">
              <b-field :label="$t('category')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" no-outline expanded :items="categories"
                           label="category_name" :disabled="form.action === 'restock'"
                           v-model="form.category" :placeholder="$t('select') + ' ' + $t('category')"></sg-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('warehouse.qty')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('warehouse.qty')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="number" id="qty" step="any" v-model.number="form.qty"
                         placeholder="0.0"></b-input>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
              <b-field label="Unit" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" no-outline expanded :items="unit" :disabled="isRemove || form.action === 'restock'"
                           v-model="form.unit" :placeholder="`${$t('select')} Unit`"></sg-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('warehouse.min_qty')" rules="required" v-slot="{ errors }" v-show="!isRemove && form.action !== 'restock'">
              <b-field :label="$t('warehouse.min_qty')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="number" id="min_qty" step="any" v-model.number="form.min_qty"
                         placeholder="0.0"></b-input>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="price" rules="required" v-slot="{ errors }" v-if="cost">
              <b-field label="Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <p class="control w-100">
                  <b-input type="text" id="product_price" v-model.number="form.product_price"
                           placeholder="Enter price"></b-input>
                </p>
                <p class="control">
                  <b-button type="is-text" class="btn-placeholder">/ {{ form.unit || 'unit' }}</b-button>
                </p>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datetimepicker
                    :placeholder="$t('date')"
                    icon="calendar-text"
                    locale="id-ID"
                    v-model="form.date">
                </b-datetimepicker>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('desc')" v-slot="{ errors }">
              <b-field :label="$t('desc')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="textarea" id="desc" v-model="form.desc"></b-input>
              </b-field>
            </ValidationProvider>

          </section>
          <footer class="has-text-right">
            <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
            <b-button native-type="submit"
                      type="is-primary"
                      class="ml-2 is-gradient">{{ $t('save') }}
            </b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapActions, mapState} from 'vuex'
import debounce from "lodash/debounce";

export default {
  name: "AddItem",
  components: {SgSelect},
  props: {
    isModal: Boolean,
    isRemove: Boolean,
  },
  computed: {
    ...mapState('farm', [
      'selectedFarm',
      'warehouse',
    ]),
    categories() {
      return this.warehouse.categories
    },
    unit() {
      return this.warehouse.unit
    },
    cost() {
      return this.$store.state.setting.cost
    },
  },
  data: () => {
    return {
      form: {
        action: 'add-item',
        category: null,
        unit: null,
        product: null,
        product_name: null,
      },
      products: [],
      isFetchingProduct: false,
    }
  },
  methods: {

    ...mapActions('farm', [
      'addWarehouse',
      'getWarehouse',
      'restockItem',
      'removeItem',
      'refreshWarehouse',
    ]),

    searchProduct: debounce(function (name) {
      if (!name.length) {
        this.products = []
        return
      }
      this.isFetchingProduct = true

      this.$store.dispatch('farm/searchWarehouse', {
        page: 1,
        farm_id: this.selectedFarm.farm_id,
        product_name: name,
        limit: 10,
      }).then(({data}) => {
        this.products = data.data || []
      }).finally(() => {
        this.isFetchingProduct = false
      })
    }, 500),

    handleSelectProduct(option) {
      this.form.category = null
      this.form.unit = null
      this.form.min_qty = null
      this.form.product = option
      this.form.action = 'add-item'
      if (option) {
        this.form.action = 'restock'
        this.form.category = {
          category_id: option.category_id,
          category_name: option.category_name,
        }
        this.form.unit = option.unit
        this.form.min_qty = 0
      }
    },

    handleSubmit() {
      this.$refs['form'].validate().then(success => {
        if (success) {
          this.$loading()
          if (this.isRemove) {
            this.removeItem({
              warehouse_id: this.form.product.warehouse_id,
              qty: this.form.qty,
              created_at: this.$timestamp(this.form.date),
              desc: this.form.desc || ''
            }).then(() => {
              this.$emit('close')
              this.refreshWarehouse()
            }).finally(() => {
              this.$loading(false)
            })
          } else if (this.form.action === 'restock') {
            this.restockItem({
              warehouse_id: this.form.product.warehouse_id,
              qty: this.form.qty,
              created_at: this.$timestamp(this.form.date),
              desc: this.form.desc || ''
            }).then(() => {
              this.$emit('close')
              this.refreshWarehouse()
            }).finally(() => {
              this.$loading(false)
            })
          } else {
            this.addWarehouse({
              farm_id: this.selectedFarm.farm_id,
              category_id: this.form.category.category_id,
              mode: 0,
              desc: this.form.desc || '',
              product_name: this.form.product_name,
              product_price: this.form.product_price || 0,
              qty: this.form.qty,
              unit: this.form.unit,
              min_qty: this.form.min_qty,
              synthesize: false,
              component: null,
              created_at: this.$timestamp(this.form.date),
            }).then(() => {
              this.$emit('close')
              this.refreshWarehouse()
            }).finally(() => {
              this.$loading(false)
            })
          }

        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 450px !important;
  max-width: 100%;
}
</style>

