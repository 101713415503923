var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{'is-shadowless': !_vm.isModal}},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"is-flex is-justify-content-space-between is-align-items-center mb-2"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.$t('warehouse.add_item')))]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1):_vm._e()]),_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('section',{staticClass:"my-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('warehouse.product_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('warehouse.product_name'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-autocomplete',{attrs:{"append-to-body":"","open-on-focus":"","keep-first":"","data":_vm.products,"placeholder":_vm.$t('search') + ' ' + _vm.$t('warehouse.product_name'),"field":"product_name","loading":_vm.isFetchingProduct},on:{"typing":_vm.searchProduct,"select":_vm.handleSelectProduct},model:{value:(_vm.form.product_name),callback:function ($$v) {_vm.$set(_vm.form, "product_name", $$v)},expression:"form.product_name"}})],1)]}}])}),(!_vm.isRemove)?_c('ValidationProvider',{attrs:{"name":_vm.$t('category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('category'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.categories,"label":"category_name","disabled":_vm.form.action === 'restock',"placeholder":_vm.$t('select') + ' ' + _vm.$t('category')},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1)]}}],null,false,1979160695)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('warehouse.qty'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('warehouse.qty'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","id":"qty","step":"any","placeholder":"0.0"},model:{value:(_vm.form.qty),callback:function ($$v) {_vm.$set(_vm.form, "qty", _vm._n($$v))},expression:"form.qty"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Unit","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.unit,"disabled":_vm.isRemove || _vm.form.action === 'restock',"placeholder":((_vm.$t('select')) + " Unit")},model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}})],1)]}}])}),_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isRemove && _vm.form.action !== 'restock'),expression:"!isRemove && form.action !== 'restock'"}],attrs:{"name":_vm.$t('warehouse.min_qty'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('warehouse.min_qty'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","id":"min_qty","step":"any","placeholder":"0.0"},model:{value:(_vm.form.min_qty),callback:function ($$v) {_vm.$set(_vm.form, "min_qty", _vm._n($$v))},expression:"form.min_qty"}})],1)]}}])}),(_vm.cost)?_c('ValidationProvider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('p',{staticClass:"control w-100"},[_c('b-input',{attrs:{"type":"text","id":"product_price","placeholder":"Enter price"},model:{value:(_vm.form.product_price),callback:function ($$v) {_vm.$set(_vm.form, "product_price", _vm._n($$v))},expression:"form.product_price"}})],1),_c('p',{staticClass:"control"},[_c('b-button',{staticClass:"btn-placeholder",attrs:{"type":"is-text"}},[_vm._v("/ "+_vm._s(_vm.form.unit || 'unit'))])],1)])]}}],null,false,1922524150)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('date'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"placeholder":_vm.$t('date'),"icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('desc')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('desc'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","id":"desc"},model:{value:(_vm.form.desc),callback:function ($$v) {_vm.$set(_vm.form, "desc", $$v)},expression:"form.desc"}})],1)]}}])})],1),_c('footer',{staticClass:"has-text-right"},[(_vm.isModal)?_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]):_vm._e(),_c('b-button',{staticClass:"ml-2 is-gradient",attrs:{"native-type":"submit","type":"is-primary"}},[_vm._v(_vm._s(_vm.$t('save'))+" ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }